import React, { useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react'; // Importa QRCodeCanvas para generar el QR
import jsPDF from 'jspdf'; // Importa jsPDF para generar el PDF

const GenerateQRModal = ({ show, handleClose, dni }) => {
  const qrRef = useRef(); // Referencia para el QR

  const handleDownloadQR = () => {
    const qrCanvas = qrRef.current.querySelector('canvas');
    const qrImage = qrCanvas.toDataURL('image/png');
    
    // Obtener la fecha y hora actual
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
    const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

    // Crear una instancia de jsPDF
    const pdf = new jsPDF();
    
    // Añadir título al PDF
    pdf.setFontSize(18);
    pdf.setFont('helvetica', 'bold');
    pdf.text('QR para validar vigencia de mis beneficios', 20, 20); // Título principal

    // Añadir subtítulo
    pdf.setFontSize(12);
    pdf.setFont('helvetica', 'normal');
    pdf.text('Este QR no es válido para viajar. Únicamente para verificar la vigencia de mis beneficios.', 20, 30); // Subtítulo

    // Añadir fecha y hora
    pdf.setFontSize(12);
    pdf.text(`Fecha de generación: ${formattedDate} ${formattedTime}`, 20, 40); // Fecha y hora

    // Añadir la imagen del QR al PDF
    pdf.addImage(qrImage, 'PNG', 15, 50, 180, 160); // Imagen QR centrada

    // Guardar el archivo PDF
    pdf.save('QR_Vigencia_Beneficios.pdf');
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Generar QR de vigencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Este QR <strong>no es válido para viajar.</strong> Es válido únicamente para verificar la vigencia de tus beneficios.</p>
        {/* Centrar el QR */}
        <div ref={qrRef} className="d-flex justify-content-center align-items-center">
          <QRCodeCanvas value={`${process.env.REACT_APP_QR_URL|| window.env.REACT_APP_QR_URL}?dni=${dni}`} size={200}/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleDownloadQR}>
          Descargar PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateQRModal;
