import React, { useState, useRef, useEffect } from 'react'; 
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom'; 
import './Styles.css';
import IMG3 from './M-removebg.png';
import IMG4 from './logo_beeg.png';
import backgroundVideo from './Cataratas2.mp4';
import VistaUsuario from '../VistaUsuario/VistaUsuario';
import Chatbot from './Chatbot/Chatbot.tsx';


const Home = () => {
  const benefitsRef = useRef(null);
  const location = useLocation(); 
  const navigate = useNavigate();

  //chat
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);



  const handleScrollToBenefits = () => {
    if (benefitsRef.current) {
      benefitsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavigateToFAQ = () => {
    navigate('/preguntas-frecuentes');
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('scrollTo') === 'benefits') {
      handleScrollToBenefits();
    }
  },  [location.search]);

  return (
    <div className="home-container">
      <video autoPlay muted loop className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
        Tu navegador no soporta la etiqueta de video.
      </video>

      <div className="video-overlay"></div>

      <div className="content-container d-flex justify-content-center align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-container">
              <h1>Consulta y gestión de beneficios dentro de la provincia de Misiones</h1>
              <div className="d-flex align-items-center flex-column flex-md-row">
                <Button variant="light" onClick={handleNavigateToFAQ}>Preguntas Frecuentes</Button>
                <div className="image-row ml-3 d-flex">
                  <img src={IMG3} alt="Beneficio" className="small-image mx-2" />
                  <img src={IMG4} alt="Beneficio" className="small-image mx-2" />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <VistaUsuario />
            </Col>
          </Row>
        </Container>
      </div>

 {/*chat*/}

 {/* Botón para abrir/cerrar el chatbot */}
<div className="chatbot-toggle">
  <button
    onClick={() => setIsChatBotOpen(!isChatBotOpen)}
    className="chatbot-button"
  >
    {isChatBotOpen ? (
      <i className="fas fa-times"></i>
    ) : (
      <i className="fas fa-comment"></i>
    )}
  </button>
</div>

{isChatBotOpen && (
  <div className="chatbot-container">
    <Chatbot /> {/* Cambia a "Chatbot" */}
  </div>
)}

      
    </div>
  );
};

export default Home;
