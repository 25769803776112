import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Card, Button, Accordion } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import 'bootstrap/dist/css/bootstrap.min.css';
import GenerateQRModal from './QRModal'; // Asegúrate de que el modal esté bien importado
import { Spinner } from 'react-bootstrap';
import './Style.css';

const VistaUsuario = () => {
  const navigate = useNavigate();
  const [benefits, setBenefits] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedUser, setSearchedUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [variant, setVariant] = useState('');
  const [expandedBenefitIndex, setExpandedBenefitIndex] = useState(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false); // Spinner
  const [showModal, setShowModal] = useState(false); // QR

  // Detectar DNI de la URL y autocompletar
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const dniFromUrl = params.get('dni');
    if (dniFromUrl) {
      setSearchTerm(dniFromUrl);
    }
  }, []); // Solo se ejecuta una vez al montar el componente

  const handleGenerateQR = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValid(!!value);
  };

  const handleSearchWithDNI = (dni) => {
    setLoading(true);
    setBenefits([]);
    setSearchedUser(null);
    setMessage(null);

    const host = process.env.REACT_APP_API_HOST || window.env.REACT_APP_API_HOST;
    const port = process.env.REACT_APP_API_PORT || window.env.REACT_APP_API_PORT;
    console.log(`Connecting to: ${host}:${port}`);

    fetch(`${host}:${port}/person/${dni}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.code === 200) {
          setBenefits(data.body.benefits);
          setSearchedUser({ dni: data.body.dni, fullname: data.body.fullname });
        } else {
          setMessage('No se encontraron beneficios para este CUIL/DNI');
          setVariant('warning');
        }
      })
      .catch((error) => {
        setMessage('Error al buscar los beneficios. Por favor, intenta más tarde.');
        setVariant('danger');
      })
      .finally(() => {
        setLoading(false);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setCaptchaValid(false);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!captchaValid) {
      setMessage('Por favor, verifica que no eres un robot.');
      setVariant('danger');
      return;
    }
    handleSearchWithDNI(searchTerm);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleBenefitDetails = (index) => {
    setExpandedBenefitIndex(expandedBenefitIndex === index ? null : index);
  };

  return (
    <div className="container-fluid p-0" id="benefits-section">
      <div className="vista-usuario-container p-4 rounded">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Mis beneficios</h1>
          {searchedUser && benefits.length > 0 && (
  <Button
    variant="primary"
    onClick={handleGenerateQR}
    className="ms-auto custom-disabled-button"
  >
    <i className="fa fa-download"></i> QR
  </Button>
)}

        </div>

        {loading && (
          <div className="text-center mt-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        )}

        <div className="search-container mt-4">
          <form onSubmit={handleSearch} className="row g-2 align-items-center">
            <div className="col">
            <input
  type="text"
  className="form-control"
  placeholder="DNI"
  value={searchTerm}
  onChange={handleSearchTermChange}
  onKeyPress={(e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault(); // Evitar que se ingresen caracteres que no sean números
    }
  }}
/>
            </div>
            <div className="col-auto">
              <button type="submit" className="btn btn-primary" disabled={!captchaValid}>
                <i className="fa fa-search"></i>
              </button>
            </div>
            <div className="col-auto">
              <div className="recaptcha-container">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={
                    process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
                    window.env.REACT_APP_RECAPTCHA_SITE_KEY
                  }
                  onChange={handleCaptchaChange}
                  size="normal"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {message && (
        <Alert variant={variant} className="mt-3">
          {message}
        </Alert>
      )}

      {benefits.length > 0 && (
        <div className="benefits-list mt-4">
          {benefits.map((benefit, index) => (
            <Card className={`mb-3 ${index === 0 ? 'mt-3' : ''}`} key={index}>
              <Card.Header as="h5" className="d-flex justify-content-between align-items-center">
                {benefit.name}
                <Button variant="success" size="sm" onClick={() => toggleBenefitDetails(index)}>
                  {expandedBenefitIndex === index ? 'Ocultar' : 'Ver beneficio'}
                </Button>
              </Card.Header>
              {expandedBenefitIndex === index && (
                <Card.Body>
                  <Accordion className="custom-accordion">
                  <Accordion.Item eventKey="0">
  <Accordion.Header>¿Cómo viajo?</Accordion.Header>
  <Accordion.Body>

    {/* Mostrar "¿Cómo viajo?" */}
    {benefit.props?.["¿Cómo viajo?"]?.value && benefit.props?.Municipio?.value && (
      <>
        {/* Mostrar siempre cómo viaja*/}
        <p>
          <i className="fa fa-check-circle" style={{ color: 'green' }}></i>{' '}
          <strong>{benefit.props["¿Cómo viajo?"].value
            .filter((v) => !v.includes("Sube vieja"))  // Filtrar el mensaje de la Sube vieja
            .join(', ')}
          </strong>
        </p>

        {/* Mostrar el mensaje sobre la Sube vieja si está presente */}
        {benefit.props["¿Cómo viajo?"].value.some((v) => v.includes("Sube vieja")) && (
          <p>
            <i className="fa fa-times-circle" style={{ color: 'red' }}></i>{' '}
            {benefit.props["¿Cómo viajo?"].value.find((v) => v.includes("Sube vieja"))}
          </p>
        )}

        {/* Mostrar municipio */}
        <p>
          <strong>Municipio:</strong> {benefit.props.Municipio.value.join(', ')}
        </p>
      </>
    )}
  </Accordion.Body>
</Accordion.Item>

                  </Accordion>

                  <Accordion className="custom-accordion mt-3" defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Vigencia</Accordion.Header>
                      <Accordion.Body>
                        {/* Mostrar vigencia y municipio */}
                        {benefit.props?.Vigencia && (
                          <>
                            {benefit.props.Vigencia.type === 'check' && benefit.props.Vigencia.value === true ? (
                              <p>
                                <i className="fa fa-check-circle" style={{ color: 'green' }}></i> <strong>Vigente hoy </strong>
                              </p>
                            ) : (
                              <p>{benefit.props.Vigencia.value}</p>
                            )}
                          
                          </>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              )}
            </Card>
          ))}
        </div>
      )}

      {/* Modal para generar el QR */}
      <GenerateQRModal show={showModal} handleClose={handleCloseModal} dni={searchTerm} />
    </div>
  );
};

export default VistaUsuario;
