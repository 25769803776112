import React, { useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa';  // Importa el icono de teléfono
import './Navbar.css';
import Logo from './Misiones provincia.png';

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOrganizationClick = () => {
    const token = localStorage.getItem('token');
    const tokenExpiry = localStorage.getItem('tokenExpiry');
    const now = new Date().getTime();

    if (token && tokenExpiry && now < parseInt(tokenExpiry, 10)) {
      navigate('/VistaBeneficiario'); 
    } else {
      navigate('/login'); 
    }
  };

  const handleScrollToBenefits = () => {
    if (window.location.pathname === '/') {
      const element = document.getElementById('benefits-section');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/', { state: { scrollToBenefits: true } });
    }
  };

  const handleLogoClick = () => {
    if (window.location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  const handleScrollToFooter = () => {
    const footerElement = document.querySelector('footer');
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.state && location.state.scrollToBenefits) {
      const element = document.getElementById('benefits-section');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand className="navbar-brand-margin" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img
          src={Logo}
          alt="Logo"
          width="55"
          height="50"
          className="d-inline-block align-top logo-image"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto padding-small">
          <Nav.Link onClick={handleScrollToBenefits}>Consultar Mis Beneficios</Nav.Link>
          <Nav.Link as={Link} to="/preguntas-frecuentes">Preguntas frecuentes</Nav.Link>
          {/*<Nav.Link onClick={handleScrollToFooter}>
            <FaPhoneAlt style={{ marginRight: '5px' }} />
          </Nav.Link>*/}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
