import React from 'react';
import { Card, Accordion, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWheelchair, faMedal, faGraduationCap, faUserTie} from '@fortawesome/free-solid-svg-icons';
import './Preguntas-Frecuentes.css';
import { FiCheck, FiX } from 'react-icons/fi';
import { FaPhoneAlt } from 'react-icons/fa';
import { useState } from 'react';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import IMG1 from './sube-vieja1.jpeg';
import IMG2 from './sube-vieja-error.jpeg'
import { faBus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const PreguntasFrecuentes = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#generales") {
      const sectionElement = document.getElementById("generales");
      const preguntaElement = document.getElementById("pregunta-contacto"); // Elemento de la pregunta específica

      if (sectionElement) {
        // Desplazar la vista hacia la sección "Generales"
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }

      if (preguntaElement) {
        // Agregar la clase de resaltado a la pregunta específica
        preguntaElement.classList.add('highlight');

        // Remover la clase después de 1 segundo
        setTimeout(() => {
          preguntaElement.classList.remove('highlight');
        }, 3000); // Resaltado por 1 segundo
      }
    }
  }, [location]);
 

  const [selectedBenefit, setSelectedBenefit] = useState(null);


  const beneficios = [
    {
      pregunta: 'BEEG',
      icon: faGraduationCap,
      id: 'beeg'
    },
    {
      pregunta: 'Discapacitados',
      icon: faWheelchair,
      id: 'discapacitados'
    },
    {
      pregunta: 'Adultos Mayores',
      icon: faUserTie,
      id: 'jubilados'
    },
    {
      pregunta: 'Ex-combatientes',
      icon: faMedal,
      id: 'excombatientes'
    },
  ];

  // Lista de medios de pago
  const mediosDePago = [
    {
      nombre: 'DNI',
      activo: true,  // Medio de pago activo
    },
    {
      nombre: 'Tarjeta de transporte',
      activo: true,  
    },
    {
      nombre: 'QR de la app billetera',
      activo: true,  
    },
    {
      nombre: 'Sube Misionera Vieja',
      activo: false,  
    },
  ];
  

  const preguntas = [
    {
      pregunta: 'Si no lo tengo, ¿Cómo solicito el BEEG?',
      respuesta: 'Al beneficio BEEG podés obtenerlo solicitándolo en la institución educativa a la que estás inscripto y asistas.',
    },
    {
      pregunta: '¿Quiénes pueden acceder al BEEG?',
      respuesta: 'Los beneficiarios del Boleto Estatal Estudiantil Gratuito Misionero son los alumnos regulares de los establecimientos educativos públicos, de gestión estatal y privada de los niveles inicial, primario, secundario y terciario autorizados por el Consejo General de Educación y aquellos alumnos regulares de las Universidades públicas y privadas que se encuentren cursando una carrera de grado.',
    },
    {
      pregunta: '¿Qué cantidad de boletos tengo por mes?',
      respuesta: (
        <ul>
          <li>Nivel Inicial (a partir de 5 años inclusive) y Primario: 40 boletos.</li>
          <li>Nivel Secundario: 60 boletos.</li>
          <li>Nivel Secundario Técnico: 80 boletos.</li>
        </ul>
      ),
    }, 
    {
      pregunta: '¿Cuánto tarda en activarse el beneficio?',
      respuesta: 'Una vez solicitado, se activa dentro de las próximas 24hs hábiles.'}
    ,
  ];

  const generales = [
    {
      pregunta: (
        <div id="pregunta-contacto">
          ¿A qué número puedo contactarme?
          <FaPhoneAlt style={{ marginLeft: '5px' }} />
        </div>
      ),
      respuesta: (
        <ul className='no-bullets'>Los números de contacto son los siguientes: <br></br><br></br>
          <li style={{color: '#3f81e0'}}><strong>Municipalidades:</strong> </li>
        
        <li><strong>Posadas:</strong> +54 9 800 888-2483 -  +54 9 3764 44-9056</li>
        <li><strong>Oberá:</strong> +54 9 3755 45-4100</li>
        <li><strong>Candelaria:</strong> +54 9 3764 49-3317</li>
        <br></br>
        <li style={{color: '#3f81e0'}}><strong>Educación</strong></li>
        <li><strong>Consejo General de educación:</strong> +54 9 800 444-0252</li>
        <li><strong>SPEPM:</strong> +54 9 3764 44-7361</li>
        <br></br>
        <li style={{color: '#3f81e0'}}><strong>Universidades:</strong></li>
        <li><strong>UNAM:</strong> +54 9 3764 48-0200</li>
        <li><strong>Montoya:</strong> +54 9 3764 46-6580</li>
        <li><strong>Ucami:</strong> +54 9 3765 03-8465 - +54 9 3764 46-3718</li>
        <li><strong>Gastón Dachary:</strong> +54 9 3765 05-1632</li>
        <li><strong>Siglo 21:</strong> +54 9 3764 42-7542</li>
        <li><strong>Cuenca del plata:</strong>+54 9 3794 04-5283</li><br></br>
        <li style={{color: '#3f81e0'}}><strong>Subsecretaría de transporte:</strong></li>
        <li>+54 9 3764 447510</li>
     
        
      </ul>
      ),
    },
    {
      pregunta: '¿Cuáles son los medios de pago? ¿Dónde estan habilitados?',
      respuesta: (
        <ul className='no-bullets'>Los medios de pago están sujetos a habilitación acorde a empresas prestadoras de cada municipio. <br></br><br></br>
          <strong>Sitema integrado:</strong> Posadas, Garupá, Candelaria.
        
        <li>✅ DNI</li>
        <li>✅ Tarjeta de transporte</li>
        <li>✅ QR de la app billetera</li>
        <li>❌ La Sube Misionera vieja no aplica.</li><br></br>
        <strong>Tramitando el boleto en la empresa: </strong>Otros municipios.
      </ul>
      ),
    },
    {
      pregunta: '¿Cómo viajo con mi DNI? ¿Qué pasa si lo pierdo?',
      respuesta: 'Puedo viajar utilizando el QR que se encuentra en la parte delantera de mi DNI físico, escaneándolo en el validador de cada colectivo. En caso de perder mi DNI, puedo desactivarlo a través de la app de billetera. Mi DNI quedará desactivado como medio de pago para viajar, dentro de las 24 horas siguientes a la solicitud.',
    },
    {
      pregunta: '¿Qué pasa si pierdo mi tarjeta de transporte?',
      respuesta: 'En caso de perder mi tarjeta de transporte, puedo desactivarla a través de la app de billetera. Mi tarjeta quedará desactivada como medio de pago para viajar, dentro de las 24 horas siguientes a la solicitud.',
    },
    {
      pregunta: '¿Cuál es el QR para viajar? ¿Cuál es el QR para validar la vigencia de mis beneficios?',
      respuesta: (
        <ul>
        <li> <FontAwesomeIcon icon={faBus} style={{ color: 'blue' }} /> <strong>El QR para viajar</strong> Lo obtengo en la&nbsp;
        <a href="https://play.google.com/store/apps/details?id=com.serviciosurbanos.misionero&pli=1" rel="noopener noreferrer" target='_blank'>
           app de billetera
        </a>. 
        Este QR actúa como un pasaje virtual, descontando el saldo disponible de la cuenta o utilizando los beneficios que te permiten viajar gratuitamente.</li>
        <li> <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'blue' }} /> <strong>El QR para validar la vigencia de los beneficios</strong> se genera en esta misma página, en la sección Mis beneficios. Solo necesitas ingresar tu DNI para realizar la consulta y podrás ver activa la opción del QR.</li> 
        
      </ul>
      )},
    
    {
      pregunta: '¿Cuál es la app billetera? ¿Cuál es su funcionalidad?',
      respuesta: (
        <span>
        La&nbsp;
       <a href="https://play.google.com/store/apps/details?id=com.serviciosurbanos.misionero&pli=1" rel="noopener noreferrer" target='_blank'>
         App de billetera&nbsp;
       </a> 
         es un medio de pago para el transporte público. Te genera un QR que puedes usar como método de pago, escaneándolo en el validador de cada colectivo para viajar. Además, te permite cargar y transferir saldo fácilmente. Obtiene más información&nbsp;
       <a href="https://www.su-sa.com.ar/billetera/" rel="noopener noreferrer" target='_blank'>
           aquí&nbsp;
        </a> o descárgala&nbsp;
        <a href="https://play.google.com/store/apps/details?id=com.serviciosurbanos.misionero&pli=1" rel="noopener noreferrer" target='_blank'>
           aquí.
        </a>
    </span>

      

      ),
    }, 
    {
      pregunta: '¿Qué pasa si detectan un fraude en la fiscalización? ¿Existe control de auditoria y control antifraude?',
      respuesta: 'Al obtener el beneficio, se aplican controles de auditoría y control antifraude para garantizar la transparencia y el uso correcto del beneficio. Si se comete un fraude con un DNI y/o otro medio, el beneficio será desactivado y se procederá a la sanción correspondiente al artículo 292 del Código Penal Argentino.',
    },
    {
      pregunta: '¿Cuál es la sube misionera vieja?',
      respuesta: ( <img 
        src={IMG1}
        alt="Sube Misionera Vieja" 
        className="mx-auto d-block"
        style={{ maxWidth: '85%', height: 'auto' }}
        onError={(e) => e.target.src = IMG2}  
      />),
    }
  ];

  const Discapacitados = [
    {
      pregunta: 'Si no lo tengo, ¿Cómo solicito el Beneficio por Discapacidad?',
      respuesta: 'El beneficio por discapacidad lo obtengo en la municipalidad de la ciudad donde resido. En caso de terner este carnet ... podés acercarte a la subsecretaría de transporte.',
    },
    {
      pregunta: '¿Cuánto tarda en activarse el beneficio?',
      respuesta: 'Una vez solicitado, se activa dentro de las próximas 24hs hábiles.',
    }
  ];
  

  const Excombatientes = [
    {
      pregunta: 'Si no lo tengo ¿Cómo obtengo el beneficio por Excombatiente?',
      respuesta: 'El beneficio por excombatiente lo obtengo solicitandolo en la subsecretaría de transporte.'
    },
    {
      pregunta: '¿Cuánto tarda en activarse el beneficio?',
      respuesta: 'Una vez solicitado, se activa dentro de las próximas 24hs hábiles.',
    }
  ];

  const Jubilados = [
    {
      pregunta: 'Si no lo tengo, ¿Cómo solicito el Beneficio por adulto mayor?',
      respuesta: 'El beneficio por adulto mayor lo obtengo solicitándolo en la municipalidad de la ciudad donde resido.',
    },
    {
      pregunta: '¿A partir de cuándo estará vigente mi beneficio por edad?',
      respuesta: 'Una vez solicitado, se activa dentro de las próximas 24hs hábiles.',
    }
      
  ];

  const handleBenefitClick = (id) => {
    setSelectedBenefit(id); 
    const element = document.getElementById(id); // Busca la sección por su ID.
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' }); // Desplaza la vista a la sección correspondiente.
    }
  }



  return (
    <div className="faq-background">
      <Container className="faq-container">
        <div className="beneficios-activos">
          <h1 className='title-faqs'>Preguntas Frecuentes</h1>
          <Row className="justify-content-center">
 {/* Carta de Beneficios */}
            <Col xs={12} md={6}>
              <Card className="mb-4 info-card">
                <Card.Body>
                  <h2 className="text-center mb-4 text-card">Beneficios</h2>
                  <p className='subtext-card'>Beneficios disponibles para consultar su vigencia dentro de la provincia de Misiones. </p>
                  <Row className="text-center justify-content-center">
                    {beneficios.map((item, index) => (
                      <Col
                        key={index}
                        xs={3}
                        className="d-flex flex-column align-items-center"
                        onClick={() => handleBenefitClick(item.id)} 
                        style={{ cursor: 'pointer' }} 
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          size="2x"
                          className={`mb-2 custom-icon ${selectedBenefit === item.id ? 'selected' : ''}`}
                        />
                        <span>{item.pregunta}</span>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* Carta de Medios de Pago */}
            <Col xs={12} md={6}>
              <Card className="mb-4 info-card">
                <Card.Body>
                  <h2 className="text-center mb-4 text-card">Medios de pago</h2>
                  <p>Medios sujetos a habilitación acorde a empresas prestadoras de cada municipio.</p>
                  <Row className="text-center justify-content-center">
                    {mediosDePago.map((item, index) => (
                      <Col key={index} xs={3} className="d-flex flex-column align-items-center">
                        {/* Íconos */}
                        {item.activo ? (
                          <FiCheck size="2em" color="#007bff" strokeWidth="3" />
                        ) : (
                          <FiX size="2em" color="red" strokeWidth="3" />
                        )}
                        <span>{item.nombre}</span>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
    </Row>
        </div>

        <div id="faq-section">
          {/*<h1 className='title-faqs'>Generales</h1>*/}
          <h2 id="generales" className='title-beeg'>Generales</h2>
          <Accordion>
            {generales.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.pregunta}</Accordion.Header>
                <Accordion.Body>{item.respuesta}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>

          <h2 id="beeg" className='title-beeg'>BEEG</h2>
          <Accordion>
            {preguntas.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.pregunta}</Accordion.Header>
                <Accordion.Body>{item.respuesta}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>

          <h2 id="discapacitados" className='title-beeg' style={{ marginTop: '25px' }}>Discapacitados</h2>
          <Accordion>
            {Discapacitados.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.pregunta}</Accordion.Header>
                <Accordion.Body>{item.respuesta}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>

          <h2 id="excombatientes" className='title-beeg' style={{ marginTop: '25px' }}>Excombatientes</h2>
          <Accordion>
            {Excombatientes.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.pregunta}</Accordion.Header>
                <Accordion.Body>{item.respuesta}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>

          <h2 id="jubilados" className='title-beeg' style={{ marginTop: '25px' }}>Adultos mayores</h2>
          <Accordion>
            {Jubilados.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.pregunta}</Accordion.Header>
                <Accordion.Body>{item.respuesta}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default PreguntasFrecuentes;
