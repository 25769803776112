import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Chatbot.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


type Message = {
  sender: 'tú' | 'asistente';
  text: React.ReactNode; // Ahora permite texto o JSX
  options?: string[];
};

// Función para normalizar el texto (elimina acentos y convierte a minúsculas)
const normalizeText = (text: string) => {
  return text
    .toLowerCase()
    .normalize('NFD') // Descompone caracteres con diacríticos (acentos)
    .replace(/[\u0300-\u036f]/g, ''); // Elimina diacríticos
};

const Chatbot: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([
    {
      sender: 'asistente',
      text: '¡Bienvenido! Haz click en una de estas opciones:',
      options: [
        '¿Para qué sirve esta web?',
        'Generales (Medios de pago - QR - App Billetera)',
        'Beneficios (Cómo solicitar y activación)',
        'Contacto'
      ],
    },
  ]);
  const [input, setInput] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false); // Nuevo estado para la animación
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);

  // Referencia para el contenedor de mensajes
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Scroll hacia abajo cuando se añaden mensajes nuevos
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (messages.length > 1) {
      scrollToBottom();
    }
  }, [messages]);

  // Función para enviar el mensaje
  const handleSendMessage = (userMessage: string) => {
    const normalizedMessage = normalizeText(userMessage); // Normalizamos el mensaje antes de procesarlo

    // Agregar el mensaje del usuario al historial
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'tú', text: userMessage },
    ]);

    // Llamar a handleUserSelection directamente con la entrada normalizada
    handleUserSelection(normalizedMessage);

    setInput(''); // Limpiar el input
  };

  // Maneja la selección de opciones o preguntas completas
  const handleUserSelection = (normalizedMessage: string) => {
    let reply: React.ReactNode = '';

    switch (normalizedMessage) {
      // Respuesta principal de la web


      case 'contacto':
        reply = (
          <ul className='no-bullets'>Los números de contacto son los siguientes: <br></br><br></br>
            <li style={{color: '#3f81e0'}}><strong>Municipalidades:</strong> </li>
          
          <li><strong>Posadas:</strong> +54 9 800 888-2483 -  +54 9 3764 44-9056</li>
          <li><strong>Oberá:</strong> +54 9 3755 45-4100</li>
          <li><strong>Candelaria:</strong> +54 9 3764 49-3317</li>
          <br></br>
          <li style={{color: '#3f81e0'}}><strong>Educación</strong></li>
          <li><strong>Consejo General de educación:</strong> +54 9 800 444-0252</li>
          <li><strong>SPEPM:</strong> +54 9 3764 44-7361</li>
          <br></br>
          <li style={{color: '#3f81e0'}}><strong>Universidades:</strong></li>
          <li><strong>UNAM:</strong> +54 9 3764 48-0200</li>
          <li><strong>Montoya:</strong> +54 9 3764 46-6580</li>
          <li><strong>Ucami:</strong> +54 9 3765 03-8465 - +54 9 3764 46-3718</li>
          <li><strong>Gastón Dachary:</strong> +54 9 3765 05-1632</li>
          <li><strong>Siglo 21:</strong> +54 9 3764 42-7542</li>
          <li><strong>Cuenca del plata:</strong>+54 9 3794 04-5283</li><br></br>
          <li style={{color: '#3f81e0'}}><strong>Subsecretaría de transporte:</strong></li>
          <li>+54 9 3764 447510</li>
       
          
        </ul>
        );
        setIsTyping(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'asistente', text: reply },
            { sender: 'asistente', text: '¿Tienes otra duda?', options: ['Sí', 'No'] },
          ]);
          setIsTyping(false);
        }, 2000);
        return;

      case 'hola':
      case 'hi':
      case 'hello':
      case 'buen dia':
      case 'buen día':
      case 'buenas tardes':
      case 'buenas noches':
        reply = '¡Hola 👋🏻! , soy un asistente virtual, no una persona. Por favor haz click en una de las siguientes opciones:';
        setIsTyping(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'asistente', text: reply },
            { sender: 'asistente', text: 'Elige una de estas opciones:', options: ['¿Para qué sirve esta web?', 'Generales (Medios de pago - QR - App Billetera)', 'Beneficios (Cómo solicitar y activación)', 'Contacto'] },
          ]);
          setIsTyping(false);
        }, 2000);
        return;

        case 'como estas?':
        case 'como estás?':
        case 'cómo estás?':
        case '¿cómo estás?':
        case '¿como estás?':
        case 'cómo te encuentras?':
          
            reply = '¡Recuerda que soy un asistente virtual! Me encuentro bien. Gracias por preguntar. Por favor haz click en una de las siguientes opciones:';
            setIsTyping(true);
            setTimeout(() => {
              setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'asistente', text: reply },
                { sender: 'asistente', text: 'Elige una de estas opciones:', options: ['¿Para qué sirve esta web?', 'Generales (Medios de pago - QR - App Billetera)', 'Beneficios (Cómo solicitar y activación)', 'Contacto'] },
              ]);
              setIsTyping(false);
            }, 2000);
            return;

        case 'chau':
        reply = '¡Adiós, que tengas un buen día👋🏻! Recuerda que tengo las siguientes opciones:';
        setIsTyping(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'asistente', text: reply },
            { sender: 'asistente', text: 'Elige una de estas opciones:', options: ['¿Para qué sirve esta web?', 'Generales (Medios de pago - QR - App Billetera)', 'Beneficios (Cómo solicitar y activación)', 'Contacto'] },
          ]);
          setIsTyping(false);
        }, 2000);
        return;
      

      case 'para que sirve esta web':
      case '¿para que sirve esta web?':
        reply = 'Esta web está diseñada para que puedas consultar tus beneficios de transporte activos y sus medios de pago, realizando una consulta con tu número de DNI. De esta manera, se eliminan las consultas y los trámites presenciales.';
        setIsTyping(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'asistente', text: reply },
            { sender: 'asistente', text: '¿Tienes otra duda?', options: ['Sí', 'No'] },
          ]);
          setIsTyping(false);
        }, 2000);
        return;

      // Categoría "Generales"
      case 'generales':
      case 'Generales (Medios de pago - QR - App Billetera)':
        reply = 'Has seleccionado Generales. Elige una opción:';
        setIsTyping(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: 'asistente',
              text: reply,
              options: ['Medios de pago', 'App Billetera', 'QR para viajar y QR de validación', 'Tarjeta de transporte nueva', 'Fraudes', 'Sube Misionera vieja'],
            },
          ]);
          setIsTyping(false);
        }, 2000);
        setCurrentCategory('generales'); // Establece la categoría actual a "Generales"
        return;

      // Categoría "Beneficios"
      case 'beneficios':
      case 'como solicitar':
      case 'activacion':
        reply = 'Has seleccionado Beneficios. Elige una opción:';
        setIsTyping(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: 'asistente',
              text: reply,
              options: ['BEEG', 'Excombatientes', 'Adultos Mayores', 'Discapacitados'],
            },
          ]);
          setIsTyping(false);
        }, 2000);
        setCurrentCategory('beneficios'); // Establece la categoría actual a "Beneficios"
        return;

      // Respuestas dentro de la categoría "Generales"
      case 'medios de pago':
        if (currentCategory === 'generales') {
          reply = (
            <>
              Los medios de pago están sujetos a habilitación acorde a empresas prestadoras de cada municipio.
              <br /><br />
              <strong>Sistema integrado:</strong> Posadas, Garupá, Candelaria.
              <ul className='no-bullets'>
                <li>✅ DNI</li>
                <li>✅ Tarjeta de transporte</li>
                <li>✅ QR de la app billetera</li>
                <li>❌ La Sube Misionera vieja no aplica.</li>
              </ul>
              <br />
              <strong>Tramitando el boleto en la empresa:</strong> Otros municipios.
            </>
          );
        } else {
          reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
        }
        break;

        case 'app billetera':
        case 'billetera':
        if (currentCategory === 'generales') {
          reply = (
            <span>
            La&nbsp;
           <a href="https://play.google.com/store/apps/details?id=com.serviciosurbanos.misionero&pli=1" rel="noopener noreferrer" target='_blank'>
             App de billetera&nbsp;
           </a> 
             es un medio de pago para el transporte público. Te genera un QR que puedes usar como método de pago, escaneándolo en el validador de cada colectivo para viajar. Además, te permite cargar y transferir saldo fácilmente. Obtiene más información&nbsp;
           <a href="https://www.su-sa.com.ar/billetera/" rel="noopener noreferrer" target='_blank'>
               aquí&nbsp;
            </a> o descárgala&nbsp;
            <a href="https://play.google.com/store/apps/details?id=com.serviciosurbanos.misionero&pli=1" rel="noopener noreferrer" target='_blank'>
               aquí.
            </a>
        </span>
        )
        } else {
          reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
        }
        break;

        case 'qr para viajar y qr de validacion':
          case 'qr para viajar':
          case 'qr':
          if (currentCategory === 'generales') {
            reply = (
              <ul>
              <li> <FontAwesomeIcon icon={faBus} style={{ color: 'blue' }} /> <strong>El QR para viajar</strong> Lo obtengo en la&nbsp;
              <a href="https://play.google.com/store/apps/details?id=com.serviciosurbanos.misionero&pli=1" rel="noopener noreferrer" target='_blank'>
                 app de billetera
              </a>. 
              Este QR actúa como un pasaje virtual, descontando el saldo disponible de la cuenta o utilizando los beneficios que te permiten viajar gratuitamente.</li>
              <li> <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'blue' }} /> <strong>El QR para validar la vigencia de los beneficios</strong> se genera en esta misma página, en la sección Mis beneficios. Solo necesitas ingresar tu DNI para realizar la consulta y podrás ver activa la opción del QR.</li> 
              
            </ul>
            )
          } else {
            reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
          }
          break;


      case 'tarjeta de transporte':
      case 'tarjeta de transporte nueva':
        if (currentCategory === 'generales') {
          reply = '...';
        } else {
          reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
        }
        break;

        case 'fraudes':
        case 'fraude':
          if (currentCategory === 'generales') {
            reply = 'Al obtener el beneficio, se aplican controles de auditoría y control antifraude para garantizar la transparencia y el uso correcto del beneficio. Si se comete un fraude con un DNI y/o otro medio, el beneficio será desactivado y se procederá a la sanción correspondiente al artículo 292 del Código Penal Argentino.';
          } else {
            reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
          }
          break;

          case 'sube misionera vieja':
            case 'sube vieja':
              case 'sube':
              if (currentCategory === 'generales') {
                reply = (
                  <>
                    La tarjeta Sube Misionera, quedó en desuso. Los nuevos medios de pago (sujetos a habilitación acorde a empresas prestadoras de cada municipio) son: 
                    <br /><br />
                    <strong>Sistema integrado:</strong> Posadas, Garupá, Candelaria.
                    <ul className='no-bullets'>
                      <li>✅ DNI</li>
                      <li>✅ Tarjeta de transporte</li>
                      <li>✅ QR de la app billetera</li>
                      
                    </ul>
                    <br />
                    <strong>Tramitando el boleto en la empresa:</strong> Otros municipios.
                  </>
                );
              } else {
                reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
              }
              break;

      // Respuestas dentro de la categoría "Beneficios"
      case 'beeg':
        if (currentCategory === 'beneficios') {
          reply = (
            <ul>Al beneficio BEEG podés obtenerlo solicitándolo en la institución educativa a la que estás inscripto y asistas. Una vez solicitado, se activa dentro de las próximas 24hs hábiles.
 
              <li>Nivel Inicial (a partir de 5 años inclusive) y Primario: 40 boletos.</li>
              <li>Nivel Secundario: 60 boletos.</li>
              <li>Nivel Secundario Técnico: 80 boletos.</li>
             <strong>Más información en la sección de preguntas frecuentes.</strong> 
            </ul>
          );
        } else {
          reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
        }
        break;

      case 'excombatientes':
      case 'combatientes':
        if (currentCategory === 'beneficios') {
          reply = 'El beneficio por excombatiente podés obtenerlo solicitandolo en la subsecretaría de transporte. Una vez solicitado, se activa dentro de las próximas 24hs hábiles.';
        } else {
          reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
        }
        break;

      case 'adultos mayores':
        case 'jubilados':
        if (currentCategory === 'beneficios') {
          reply = 'El beneficio por adulto mayor podés obtenerlo solicitándolo en la municipalidad de la ciudad donde residas. Una vez solicitado, se activa dentro de las próximas 24hs hábiles.';
        } else {
          reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
        }
        break;

      case 'discapacitados':
        if (currentCategory === 'beneficios') {
          reply = 'El beneficio por discapacidad podés obtenerlo solicitándolo en la municipalidad de la ciudad donde residas. En caso de terner este carnet ... podés acercarte a la subsecretaría de trasnporte. Una vez solicitado, se activa dentro de las próximas 24hs hábiles.';
        } else {
          reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
        }
        break;

      // Manejo de "Sí" o "No" en la conversación
      case 'si':
      case 'sí':
        reply = 'Elige entre las opciones disponibles.';
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: 'asistente',
            text: reply,
            options: ['¿Para qué sirve esta web?',
        'Generales (Medios de pago - QR - App Billetera)',
        'Beneficios (Cómo solicitar y activación)',
        'Contacto'],
          },
        ]);
        setCurrentCategory(null); // Reseteamos la categoría para permitir nueva navegación
        return;

      case 'no':
        reply = '¡Gracias por usar el chatbot! Hasta pronto.';
        setIsTyping(true);
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'asistente', text: reply },
          ]);
          setIsTyping(false); // Desactivar la animación de escritura
        }, 2000);
        setCurrentCategory(null); // Reseteamos la categoría para finalizar
        return;

      default:
        reply = 'Lo siento, no entiendo esa opción. Por favor, elige una de las opciones disponibles.';
    }

    // Mostrar la respuesta del asistente con animación de escritura
    setIsTyping(true); // Activar la animación de escritura
    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'asistente', text: reply },
      ]);

      // Si no es un caso de "sí" o "no", preguntar si tiene otra duda
      if (!['si', 'sí', 'no'].includes(normalizedMessage)) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'asistente', text: '¿Tienes otra duda?', options: ['Sí', 'No'] },
        ]);
      }

      setIsTyping(false); // Desactivar la animación de escritura
    }, 2000); // Tiempo de espera para simular la escritura
  };

  // Esta función simula que el usuario está escribiendo la opción al hacer clic en un botón
  const handleClickOption = (option: string) => {
    let userChoice = normalizeText(option); // Normalizamos el texto de la opción

    if (userChoice.includes('generales')) {
      userChoice = 'generales';
    } else if (userChoice.includes('beneficios')) {
      userChoice = 'beneficios';
    }

    handleSendMessage(userChoice); // Simula que el usuario envía el texto de la opción
  };

  return (
    <div className="chatbot-wrapper">
      <div className="chatbot-container card shadow-lg">
        {/* Header del chatbot */}
        <div className="chatbot-header">
          <h5 className="chatbot-title">Chatbot de Ayuda</h5>
          <p> Para información más detallada, visita nuestra sección de preguntas frecuentes.</p>
        </div>

        <div className="card-body chatbot-body">
          <div className="messages-box">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.sender === 'tú' ? 'user-message' : 'bot-message'
                }`}
              >
                <strong>
                  {message.sender === 'tú' ? 'Tú' : 'Asistente'}:
                </strong>{' '}
                {message.text}

                {/* Mostrar las opciones si el mensaje las contiene */}
                {message.options && (
                  <div className="options-container">
                    {message.options.map((option, i) => (
                      <button
                        key={i}
                        className="btn btn-info option-button"
                        onClick={() => handleClickOption(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}

            {/* Mostrar la animación de escritura si el asistente está escribiendo */}
            {isTyping && (
              <div className="message bot-message">
                <div className="typing-bubbles">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}

            {/* Este div sirve como referencia para hacer scroll hacia abajo */}
            <div ref={messagesEndRef} />
          </div>

          {/* Input manual para escribir mensaje o seleccionar opción */}
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) =>
              e.key === 'Enter' && handleSendMessage(input)
            }
            className="form-control chatbot-input"
            placeholder="Escribe o selecciona una opción..."
          />
          <button
            onClick={() => handleSendMessage(input)}
            className="btn btn-primary mt-2 w-100"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
