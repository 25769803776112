import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './Componentes/Navbar.js/Navbar'; 
import Home from './Componentes/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './Componentes/ScrollToTop/ScrollToTop';
import Footer from './Componentes/Footer/Footer.tsx';
import PreguntasFrecuentes from './Componentes/PreguntasFrecuentes/Preguntas-Frecuentes';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavigationBar /> 
      <Routes>
        <Route path="/" element={<Home />} />
    
       <Route path="/preguntas-frecuentes" element={<PreguntasFrecuentes />} />
       
      </Routes>
      <Footer />
      
    </Router>
  );
}

export default App;
