import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa'; 
import './Footer.css'; 

const Footer = () => {

  const handleContactClick = (event) => {
    event.preventDefault(); 
    
    // Navegamos directamente usando window.location.href para incluir el hash
    window.location.href = "/preguntas-frecuentes#generales";
  };

  return (
    <footer className="footer py-3">
      <Container>
        <hr className="footer-separator" />
        <Row className="py-2">
          <Col xs={12} md={6} className="text-center text-md-left mb-2 mb-md-0">
            <p className="mb-0">
              <FaMapMarkerAlt className="footer-icon" /> Subsecretaría de Transporte
            </p>
            <p className="mb-0">Av. Roque Sáenz Peña 2276, Posadas, Misiones, Argentina.</p>
          </Col>

          <Col xs={12} md={6} className="text-center text-md-right">
            <p className="mb-0">
              <a href="#generales" className="footer-link" onClick={handleContactClick}>
                <FaPhone className="footer-icon" /> Sección contactos
              </a>
            </p>
          </Col> 
        </Row>
        <hr className="footer-separator" />
        <Row className="justify-content-center mt-2">
          <Col xs={12} className="text-center">
            <p className="small mb-0">
              © Todos los derechos reservados.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
